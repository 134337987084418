@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap");

:root {
  --animate-duration: 0.75s;
}

.scroll-more-container {
  display: flex;
  justify-content: flex-start;
  max-width: max-content;
  margin-top: 10px;
}

.arrow-container {
  max-width: max-content;
  position: relative;
}

.arrow-1 {
  width: 40px;
  height: 40px;
  background: #243d82;
  opacity: 0.5;
  border-radius: 50%;
}

.arrow-2 {
  width: 25px;
  height: 25px;
  background: #243d82;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-2:before {
  width: 27px;
  height: 27px;
  content: "";
  border: 2px solid #d9d1c7;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  left: -3px;
}

.arrow-2 #iconScrollMore {
  font-size: 20px;
  text-align: center;
  color: #d9d1c7;
}

/* Custom Animate.css */

.animated.hinge {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.4, 0.4, 0.4);
    transform: scale3d(0.4, 0.4, 0.4);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.4, 0.4, 0.4);
    transform: scale3d(0.4, 0.4, 0.4);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
